import { LinkGroupStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import clsx from 'clsx';
import { useState } from 'react';
import Image from 'next/image';
import chevronDownIcon from '@/assets/chevron-down.svg';
import { NavItem } from './navItem';
import { StoryblokStory } from 'storyblok-generate-ts';

export const MobileHeaderNavSection = ({
  links,
  selected,
  isVisible,
  story,
  component,
}: {
  links: LinkGroupStoryblok;
  selected?: boolean;
  isVisible: boolean;
  story: StoryblokStory<StoryblokComponent>;
  component: StoryblokComponent;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <li role="menuitem">
        <button
          aria-hidden={!isVisible}
          aria-expanded={isOpen}
          aria-controls={`mobile-menu-${links._uid}`}
          className={clsx('w-full flex flex-row items-center justify-between p-6', selected && 'bg-brandGreen-100')}
          onClick={() => setIsOpen(!isOpen)}
          tabIndex={!isVisible ? -1 : undefined}
        >
          <div className="flex flex-row items-center gap-2 w-full justify-start">
            {selected && <div className="h-2.5 w-2.5 bg-elty-green rounded-full" />}
            <span className="font-medium">{links.title}</span>
          </div>
          <Image aria-hidden className={clsx(!isOpen && 'rotate-180')} src={chevronDownIcon} alt="icona apertura menu" />
        </button>
        <nav
          className={clsx(
            'top-0 left-0 h-full w-full transition-all  z-10',
            isOpen ? 'opacity-100 max-h-96 duration-700' : 'opacity-0 max-h-0 overflow-hidden duration-300',
          )}
          aria-labelledby={`top-section-label-${links._uid}`}
          aria-hidden={!isOpen}
          id={`mobile-menu-${links._uid}`}
        >
          <ul role="menu" className="p-8 flex flex-col gap-5">
            {links.links.map(
              link =>
                link.component === 'Link' && <NavItem isVisible={isOpen} link={link} key={link._uid} story={story} component={component} />,
            )}
          </ul>
        </nav>
      </li>
    </>
  );
};
