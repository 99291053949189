import { AssetStoryblok } from '@/types/types-storyblok';

// https://www.storyblok.com/faq/image-dimensions-assets-js
export function getImageDimensions(image: Pick<AssetStoryblok, 'filename'>): { width: number; height: number } {
  const url = image.filename;
  const parts = url.split('/');
  if (parts.length < 6) {
    return { width: 0, height: 0 };
  }
  return {
    width: Number(url.split('/')?.[5]?.split('x')?.[0]),
    height: Number(url.split('/')?.[5]?.split('x')?.[1]),
  };
}
